html, body, body>#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome 6.0+, Safari 3.1+, Edge & Opera 15+ */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE 10+ and Edge */
  user-select: none;
}

ul {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.nonstickyheader-display .row {
  margin-left: 0px; 
  margin-right: 0px;
}

@media only screen and (min-width: 768px) {
  #bgcover {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -100;
    width: 100vw !important;
    height: 100vh !important;
  }

  .nonstickyheader-display {
    display:none
  } 
}

@media only screen and (max-width: 767px) {
  #bgcover {
    padding-top: 35px;
    height: auto !important;
    width: 100% !important;
  }

  .stickyheader-display {
    display:none
  }
}

#bgcover>video {
  object-fit: cover;
}

#canvasfor-googlemap img.text-marker {
  max-width: none!important;
  background: none!important;
}

img {
  max-width: none
}